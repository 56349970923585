import React from 'react';
import '@fontsource/roboto-condensed/700.css';
import '@fontsource/poppins/400.css';
import '@fontsource/poppins/600.css';
import '@fontsource/poppins/700.css';
import '@fontsource/kalam/400.css';
import '@fontsource/dm-serif-display/400.css';
import '../css/layout.scss';
import ConstructionSvg from '../images/construction.svg';

export default function Top() {
  return (
    <div className="layout">
      <div className="w-full flex flex-col items-center justify-center pt-48 gap-12">
        <img className="w-48 text-blue-50" src={ConstructionSvg} />
        <h1>Men at work</h1>
      </div>
    </div>
  );
}
